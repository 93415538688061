import { getStatementsWithoutThemes } from 'helpers/reports/getStatementsWithoutThemes'

export const getStatementsToRender = (
	statementsTotalSegmentData,
	studyObjectData,
	slideSettings,
) => {
	const { themeViewSettings } = slideSettings

	if (themeViewSettings.isActive === false) {
		return statementsTotalSegmentData.statementSupports
	}

	if (themeViewSettings.showTopUnthemedStatements === false) {
		return []
	}

	const statementsWithoutThemes =
		themeViewSettings.showUnthemedAnswers === false
			? []
			: getStatementsWithoutThemes(
					studyObjectData.statements.filter(statement => statement.isInvalid === false),
					studyObjectData.statementGroups,
					studyObjectData.statementThemes,
			  )

	return statementsTotalSegmentData.statementSupports.filter(statement =>
		statementsWithoutThemes.some(
			unthemedStatement => statement.idStatement === unthemedStatement.idStatement,
		),
	)
}
