import _ from 'lodash'
import { compare } from 'natural-orderby'

import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getHasThemesData } from 'helpers/reportBuilder/getHasThemesData'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { createLines } from 'helpers/reportBuilder/chart/createLines'
import { getStatementsToRender } from 'helpers/reports/getStatementsToRender'
import { filterOutEmptyThemes } from 'helpers/reports/filterOutEmptyThemes'

import { tools } from 'constants/tools'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

/**
 * HELPERS
 */
const toPercent = number => _.round(_.round(number, 2) * 100, 2)

const calculateIdeaData = (idea, chosenSegmentData) => {
	const supportData = chosenSegmentData.statementSupports.find(
		statement => statement.idStatement === idea.idStatement,
	)

	return {
		...idea,
		support: {
			expectedSupport: supportData.expectedSupport,
			supportStrength: supportData.supportStrength,
			totalSeen: supportData.totalSeen,
		},
		support_total: {
			expectedSupport: idea.expectedSupport,
			supportStrength: idea.supportStrength,
			totalSeen: idea.totalSeen,
		},
	}
}

const getLines = answer => {
	const lengthRangeTo = 50
	const lengthLimit = 70

	const ideaLines = createLines(answer, lengthRangeTo, lengthLimit)

	return ideaLines.join('\n')
}

const calculateThemeData = (theme, totalSegmentThemesData, chosenSegmentThemeData, settings) => {
	const totalSegmentThemeData = totalSegmentThemesData.themeSupport[theme.idStatementTheme]
	const themeData = chosenSegmentThemeData.themeSupport[theme.idStatementTheme]

	const customColor = _.get(settings, `customColors.${theme.idStatementTheme}`, null)

	const themeName = getStatementThemeName(theme, true)

	return {
		absoluteSupport: toPercent(themeData.absoluteSupport),
		answersCount: theme.statements.length,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			themeData.supportStrength.lowerBound,
		),
		expectedSupport: toPercent(themeData.expectedSupport),
		isExcluded: settings.hiddenStatements.includes(theme.idStatementTheme),
		fill: customColor ?? theme.color,
		hasCustomColor: customColor !== null,
		idStatement: theme.idStatementTheme,
		id: theme.idStatementTheme,
		letter: totalSegmentThemeData.letter,
		name: themeName,
		answer: themeName,
		relativeSupport: null,
		supportCompletes: null,
		supportPercent: toPercent(themeData.supportStrength.lowerBound),
		totalExpectedSupport: toPercent(totalSegmentThemeData.expectedSupport),
		totalSupportCompletes: null,
		totalSupportPercent: toPercent(totalSegmentThemeData.supportStrength.lowerBound),
		isTheme: true,
	}
}

const roundIdea = idea => ({
	...idea,
	relativeSupport: _.round(idea.relativeSupport, 2),
	support_total: {
		...idea.support_total,
		expectedSupport: toPercent(_.get(idea, 'support_total.expectedSupport', 0)),
		supportStrength: {
			...idea.support_total.supportStrength,
			lowerBound: toPercent(_.get(idea, 'support_total.supportStrength.lowerBound', 0)),
		},
	},
	support: {
		...idea.support,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(idea, 'support.supportStrength.lowerBound', 0),
		),
		expectedSupport: toPercent(_.get(idea, 'support.expectedSupport', 0)),
		supportStrength: {
			...idea.support.supportStrength,
			lowerBound: toPercent(_.get(idea, 'support.supportStrength.lowerBound', 0)),
		},
	},
})

const sortIdeaByTotal = (a, b) => {
	const res =
		a.support_total.supportStrength.lowerBound - b.support_total.supportStrength.lowerBound

	if (res < 0) return 1
	if (res > 0) return -1

	return compare(a.label, b.label)
}

const formatIdea = (idea, allIdeas, settings, statementThemes, excludeList) => {
	const statementThemeParams = getThemeParams(
		idea,
		idea,
		statementThemes,
		allIdeas,
		settings.themeViewSettings.isActive === false ? settings.useThemes : false,
	)

	const themeName = getStatementThemeName(statementThemeParams, true)

	const customColor = _.get(settings, `customColors.${idea.idStatement}`, null)

	return {
		absoluteSupport: idea.absoluteSupport,
		decimalSupportPercent: idea.support.decimalSupportPercent,
		expectedSupport: idea.support.expectedSupport,
		fill: customColor ?? statementThemeParams.color,
		hasCustomColor: customColor !== null,
		idStatement: idea.idStatement,
		id: idea.idStatement,
		letter: idea.letter,
		isExcluded: excludeList.includes(idea.idStatement),
		answer: getLines(idea.label),
		name: idea.label,
		themeName,
		relativeSupport: idea.relativeSupport,
		supportCompletes: idea.support.totalSeen,
		supportPercent: idea.support.supportStrength.lowerBound,
		totalExpectedSupport: idea.support_total.expectedSupport,
		totalSupportCompletes: idea.support_total.totalSeen,
		totalSupportPercent: idea.support_total.supportStrength.lowerBound,
		isTheme: false,
	}
}

const addAiDescriptionData = (ideaOrTheme, settings) => ({
	...ideaOrTheme,
	aiDescription: {
		label: ideaOrTheme.answer,
		[settings.idsSegments[0]]: ideaOrTheme.totalExpectedSupport,
	},
})

/**
 * SELECTORS & CALCULATORS
 */
export const calculateDataset = (idStudy, calculatedSlide, blobData, allStudyObjectData) => {
	const idStudyObject = _.get(calculatedSlide, 'settings.idStudyObject', null)
	const idsSegments = _.get(calculatedSlide, 'settings.idsSegments', [])

	if (allStudyObjectData === undefined) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	if (calculatedSlide.slideType !== REPORT_TYPES.IDEACLOUD) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const {
		settings,
		legendState: { sorted, filtered },
	} = calculatedSlide

	const { themeViewSettings } = settings

	const statementsTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const statementSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				idsSegments[0],
			)
		]

	const themesTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const themesSegmentData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idsSegments[0])
		]

	if (
		hasBlobData(
			[statementsTotalSegmentData, statementSegmentData],
			SUPPORT_KEYS.STATEMENT_SUPPORTS,
		) === false
	) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	if (
		themeViewSettings.isActive === true &&
		hasBlobData([themesTotalSegmentData, themesSegmentData], SUPPORT_KEYS.THEME_SUPPORT) === false
	) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const studyObjectData = allStudyObjectData[calculatedSlide.settings.idStudyObject]

	if (studyObjectData === undefined) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const statementsToRender = getStatementsToRender(
		statementsTotalSegmentData,
		studyObjectData,
		settings,
	)

	const { statementThemes } = studyObjectData
	const themesToRender =
		themeViewSettings.isActive === true ? filterOutEmptyThemes(statementThemes) : []

	const isThemesSupportDefined = getHasThemesData(themesToRender, themesTotalSegmentData)

	if (isThemesSupportDefined === false) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const themesData = themesToRender
		.map(theme => calculateThemeData(theme, themesTotalSegmentData, themesSegmentData, settings))
		.map(theme => addAiDescriptionData(theme, settings))

	const statementsData = statementsToRender
		.map(idea => calculateIdeaData(idea, statementSegmentData))
		.map(roundIdea)
		.slice()
		.sort(sortIdeaByTotal)
		.map((idea, _i, allIdeas) =>
			formatIdea(
				idea,
				allIdeas,
				calculatedSlide.settings,
				statementThemes,
				settings.hiddenStatements,
			),
		)
		.map(idea => addAiDescriptionData(idea, settings))

	const allData = [...themesData, ...statementsData]

	const sortedAndFilteredStatements = sortData(filterData(allData, filtered), sorted)

	return {
		statementSupports: sortedAndFilteredStatements,
		allIdeasLength: allData.length,
	}
}

export const calculateLegendStatements = (calculatedSlide, dataset) => {
	const { hiddenStatements } = calculatedSlide.settings

	return dataset.statementSupports
		.filter(statement => hiddenStatements.includes(statement.idStatement) === false)
		.map(statement => {
			const baseToExport = [statement.letter, statement.name]
			const dataToExport =
				statement.isTheme === true
					? [...baseToExport, statement.answersCount, statement.decimalSupportPercent]
					: [
							...baseToExport,
							statement.decimalSupportPercent,
							statement.supportCompletes,
							statement.themeName,
					  ]

			return {
				...statement,
				toExport: () => dataToExport,
			}
		})
}
