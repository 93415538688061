import { gql } from '@apollo/client'
import { prepareMutation } from 'store/tools/graphql'

import { GET_PRESENTATION, GET_PRESENTATIONS_LIST } from 'gql/queries/presentation'
import { mediaObject } from 'gql/fragments/mediaObject'

export const CREATE_PRESENTATION = (idAccount, onCreateCompleted, onCreateError) => {
	return prepareMutation({
		options: { onCompleted: onCreateCompleted, onError: onCreateError },
		mutation: gql`
			mutation CREATE_PRESENTATION($idAccount: UUID!, $presentationInput: PresentationInput!) {
				presentation {
					create(idAccount: $idAccount, presentationInput: $presentationInput) {
						idPresentation
						settings
						slides
						label
						numberOfSlides
						meta {
							created
							updated
						}
						mediaFiles {
							...mediaObject
						}
					}
				}
			}

			${mediaObject}
		`,
		update: (cache, { data: { presentation } }) => {
			const cachedData = cache.readQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
			})

			if (cachedData === null) {
				return
			}

			const { presentations } = cachedData

			cache.writeQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
				data: { presentations: [...presentations, presentation.create] },
			})
		},
	})
}

export const DELETE_PRESENTATION = (idAccount, onDeleteCompleted, onDeleteError) => {
	return prepareMutation({
		options: { onCompleted: onDeleteCompleted, onError: onDeleteError },
		mutation: gql`
			mutation DELETE_PRESENTATION($idPresentation: UUID!) {
				presentation {
					delete(idPresentation: $idPresentation) {
						idPresentation
					}
				}
			}
		`,
		update: (cache, { data: { presentation } }) => {
			const cachedData = cache.readQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
			})

			if (cachedData === null) {
				return
			}

			const { presentations } = cachedData

			cache.writeQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
				data: {
					presentations: presentations.filter(
						cahcedPresentation =>
							cahcedPresentation.idPresentation !== presentation.delete.idPresentation,
					),
				},
			})
		},
	})
}

export const UPDATE_PRESENTATION = (idAccount, onCompleted, onError) => {
	return prepareMutation({
		options: { onCompleted, onError },
		mutation: gql`
			mutation UPDATE_PRESENTATION(
				$idPresentation: UUID!
				$idsMediaToDelete: [UUID!]!
				$presentationInput: PresentationInput!
			) {
				presentation {
					update(
						idPresentation: $idPresentation
						idsMediaToDelete: $idsMediaToDelete
						presentationInput: $presentationInput
					) {
						idPresentation
						numberOfSlides
						label
						settings
						slides
					}
				}
			}
		`,
		update: (cache, { data: { presentation } }) => {
			const cachedData = cache.readQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
			})

			if (cachedData === null) {
				// GET_PRESENTATIONS_LIST was not called so there's nothing to update
				return
			}

			const presentations = cachedData.presentations

			cache.writeQuery({
				query: GET_PRESENTATIONS_LIST(idAccount).query,
				variables: { idAccount },
				data: {
					presentations: [
						...presentations.filter(
							cachedPresentation =>
								cachedPresentation.idPresentation !== presentation.update.idPresentation,
						),
						{
							...presentations.find(
								cachedPresentation =>
									cachedPresentation.idPresentation === presentation.update.idPresentation,
							),
							...presentation.update,
						},
					],
				},
			})
		},
	})
}

export const ADD_PRESENTATION_MEDIA_OBJECT = variables =>
	prepareMutation({
		variables,
		mutation: gql`
			mutation ADD_PRESENTATION_MEDIA_OBJECT(
				$idPresentation: UUID!
				$addMediaObjectInput: AddMediaObjectInput!
			) {
				presentation {
					addPresentationMediaObject(
						idPresentation: $idPresentation
						addMediaObjectInput: $addMediaObjectInput
					) {
						...mediaObject
						mediaUploadUrl
					}
				}
			}

			${mediaObject}
		`,
		update: (cache, { data }) => {
			const { idPresentation } = variables
			const { presentation } = cache.readQuery({ ...GET_PRESENTATION(idPresentation) })
			const newMediaObject = data.presentation.addPresentationMediaObject

			cache.writeQuery({
				...GET_PRESENTATION(idPresentation),
				data: {
					presentation: {
						...presentation,
						mediaFiles: [...presentation.mediaFiles, newMediaObject],
					},
				},
			})
		},
	})

export const SET_PRESENTATION_PUBLIC_TOKEN = (onCompleted, onError) =>
	prepareMutation({
		options: { onCompleted, onError },
		mutation: gql`
			mutation SET_PRESENTATION_PUBLIC_TOKEN($idPresentation: UUID!) {
				presentation {
					setPublicToken(idPresentation: $idPresentation) {
						idPresentation
						publicToken
					}
				}
			}
		`,
	})

export const REMOVE_PRESENTATION_PUBLIC_TOKEN = onError =>
	prepareMutation({
		options: { onError },
		mutation: gql`
			mutation REMOVE_PRESENTATION_PUBLIC_TOKEN($idPresentation: UUID!) {
				presentation {
					removePublicToken(idPresentation: $idPresentation) {
						idPresentation
						publicToken
					}
				}
			}
		`,
	})
