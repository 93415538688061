export const REPORT_BUILDER_COLORS = [
	'#2196F3',
	'#F44336',
	'#009688',
	'#03A9F4',
	'#FFC107',
	'#E91E63',
	'#00BCD4',
	'#FF5722',
	'#4CAF50',
	'#9C27B0',
	'#FF9800',
	'#8BC34A',
	'#814BE1',
	'#CDDC39',
	'#475AC4',
]
