/**
 * Imports
 */
import _ from 'lodash'

import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'

import { tools } from 'constants/tools'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

const getStatementData = (
	statement,
	totalSegmentData,
	statementThemes,
	ideas,
	chosenSegmentStatementData,
	useThemes,
) => {
	const totalSegmentStatement = totalSegmentData.statementSupports[statement.idStatement]
	const themeParams = getThemeParams(
		statement,
		totalSegmentStatement,
		statementThemes,
		ideas,
		useThemes,
	)

	return {
		angle: statement.angle,
		color: themeParams.color,
		distance: statement.distance,
		idStatement: statement.idStatement,
		label: totalSegmentStatement.label,
		letter: totalSegmentStatement.letter,
		supportCompletes: chosenSegmentStatementData.totalSeen,
		supportPercent: chosenSegmentStatementData.supportStrength,
		themeName: getStatementThemeName(themeParams, true),
		totalSupportCompletes: totalSegmentStatement.totalSeen,
		totalSupportPercent: totalSegmentStatement.supportStrength,
	}
}

/**
 * Munge Functions
 */
const mungeIdeas = (ideas, slideSettings, legendState) => {
	const chartIdeas = ideas.map((idea, index) => {
		const correlation = Math.round((1 - idea.distance) * 100) / 100
		const correlationToExport = getDecimalPointNumberFromLeadingZeroNumber(1 - idea.distance, 0, 4)

		const supportPercent = Math.round(idea.supportPercent.lowerBound * 100, 0)

		const totalSupportPercent = Math.round(idea.totalSupportPercent.lowerBound * 100, 0)
		const totalSupportPercentToExport = getDecimalPointNumberFromLeadingZeroNumber(
			idea.totalSupportPercent.lowerBound,
		)

		const customColor = _.get(slideSettings, `customColors.${idea.idStatement}`, null)

		return {
			angle: idea.angle,
			correlation: Number(correlation),
			distance: idea.distance,
			fill: customColor ?? idea.color,
			hasCustomColor: customColor !== null,
			id: idea.idStatement,
			letter: idea.letter,
			name: idea.label,
			shown: true,
			supportCompletes: idea.supportCompletes,
			supportPercent,
			themeName: idea.themeName,
			totalSupportCompletes: idea.totalSupportCompletes,
			totalSupportPercent,
			aiDescription: {
				label: idea.label,
				correlation: Number(correlation),
			},

			toExport: () => [
				idea.letter,
				idea.label,
				Number(correlationToExport),
				Number(totalSupportPercentToExport),
				Number(idea.totalSupportCompletes),
				idea.themeName || '',
			],
		}
	})
	// TODO: uncomment filter if/after we add UI for settings
	// .filter(
	// 	i =>
	// 		i.totalSupportPercent >= slideSettings.support.min &&
	// 		i.totalSupportPercent <= slideSettings.support.max &&
	// 		(1 - i.distance) * 100 >= slideSettings.correlation.min &&
	// 		(1 - i.distance) * 100 <= slideSettings.correlation.max,
	// )

	return sortData(filterData(chartIdeas, legendState.filtered), legendState.sorted)
}

const getData = (idStudy, reportType, studyObjectData, slideSettings, legendState, blobData) => {
	if (reportType !== REPORT_TYPES.CONSENSUS || slideSettings === undefined) {
		return []
	}

	if (studyObjectData === undefined) {
		return []
	}

	const { idStudyObject, idsSegments, centralStatement } = slideSettings
	const [idSegment] = idsSegments

	const data =
		blobData[getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_CONSENSUS, idSegment)]
	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_CONSENSUS,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (hasBlobData([data, totalSegmentData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false) {
		return []
	}

	const chartData = data.consensus[centralStatement]

	if (_.isNil(chartData) === true) {
		return {
			chartData: [],
			totalSegmentData: [],
		}
	}

	return {
		data,
		chartData,
		totalSegmentData,
	}
}

export const getAllIdeas = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	legendState,
	blobData,
) => {
	const { chartData } = getData(
		idStudy,
		reportType,
		studyObjectData,
		slideSettings,
		legendState,
		blobData,
	)

	return chartData
}

/**
 * Calculators
 */
export const calculateLegendIdeas = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	legendState,
	blobData,
) => {
	const { options: hiddenOptions, useThemes } = slideSettings

	const { data, chartData, totalSegmentData } = getData(
		idStudy,
		reportType,
		studyObjectData,
		slideSettings,
		legendState,
		blobData,
	)

	const ideas = chartData.map(statement =>
		getStatementData(
			statement,
			totalSegmentData,
			studyObjectData.statementThemes,
			chartData,
			data.statementSupports[statement.idStatement],
			useThemes,
		),
	)

	return mungeIdeas(
		ideas.filter(statement => hiddenOptions.includes(statement.idStatement) === false),
		slideSettings,
		legendState,
	)
}
