import { prepareQuery } from 'store/tools/graphql'
import { gql } from '@apollo/client'

import {
	accountUserInformation,
	notification,
	notificationSettings,
	recentUpdates,
	userDetail,
} from 'gql/fragments/user'
import {
	accountInfo,
	accountLicense,
	billingInfo,
	roleDefinition,
	subscription,
	invitation,
} from 'gql/fragments/account'

import { NOTIFICATIONS_PAGE_SIZE } from 'constants/user'

/**
 * Queries
 */
export const USER_PAGE = () =>
	prepareQuery(
		{
			query: gql`
				query USER_PAGE {
					user {
						idUser
						...userDetail
						isActive
						isInternalEmployee
						isSystemAdmin
						shouldAcceptNewPrivacyPolicy
						shouldAcceptNewTerms
						...notificationSettings
						newNotifications(limit: ${NOTIFICATIONS_PAGE_SIZE}, skip: 0) {
							...notification
						}
						seenNotifications(limit: ${NOTIFICATIONS_PAGE_SIZE}, skip: 0) {
							...notification
						}
						invitations {
							...invitation
						}
						tags {
							ignoreGsMailCheck
						}
						featureFlags {
							enableAdvancedFlowFeatures
							enableLiveSlidesAiDescription
						}
						meta {
							created
						}
					}
					
					accounts {
							idAccount
							...accountInfo
							...accountLicense
							...billingInfo
							...roleDefinition
							...subscription
							numberOfStudies
							numberOfUsers
              users {
                ...accountUserInformation
              }
							invitations {
								...invitation
							}
						}
				}
			`,
		},
		[
			accountInfo,
			accountLicense,
			accountUserInformation,
			billingInfo,
			invitation,
			notification,
			notificationSettings,
			roleDefinition,
			subscription,
			userDetail,
		],
	)

export const UNREAD_NOTIFICATIONS = () =>
	prepareQuery(
		{
			variables: { limit: NOTIFICATIONS_PAGE_SIZE, skip: 0 },
			options: {
				notifyOnNetworkStatusChange: true,
			},
			query: gql`
				query UNREAD_NOTIFICATIONS($limit: Int!, $skip: Int!) {
					user {
						idUser
						newNotifications(limit: $limit, skip: $skip) {
							...notification
						}
					}
				}
			`,
		},
		[notification],
	)

export const SEEN_NOTIFICATIONS = () =>
	prepareQuery(
		{
			variables: { limit: NOTIFICATIONS_PAGE_SIZE, skip: 0 },
			options: {
				notifyOnNetworkStatusChange: true,
			},
			query: gql`
				query SEEN_NOTIFICATIONS($limit: Int!, $skip: Int!) {
					user {
						idUser
						seenNotifications(limit: $limit, skip: $skip) {
							...notification
						}
					}
				}
			`,
		},
		[notification],
	)

export const RECENT_UPDATES = () =>
	prepareQuery(
		{
			options: { fetchPolicy: 'network-only' },
			query: gql`
				query RECENT_UPDATES {
					user {
						idUser
						...recentUpdates
					}
				}
			`,
		},
		[recentUpdates],
	)
