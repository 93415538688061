import _ from 'lodash'

import { tools } from 'constants/tools'

import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getLetterAndThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { getShouldUseThemes } from 'helpers/reportBuilder/getShouldUseThemes'
import { getStatementThemeName } from 'helpers/statementThemes'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'
import { getStatementsToRender } from 'helpers/reports/getStatementsToRender'
import { filterOutEmptyThemes } from 'helpers/reports/filterOutEmptyThemes'

import {
	getSegmentTotalSupportPercentageKey,
	getSegmentTotalSupportCompletesKey,
	getSegmentTotalSupportLowerBoundKey,
	getSegmentTotalSupportUpperBoundKey,
	getSegmentConfidenceIntervalKey,
	getSegmentAgreeCountKey,
	getSegmentDisagreeCountKey,
	getSegmentIndifferentCountKey,
	getSegmentTotalSupportDecimalPercentageKey,
	getSegmentConfidenceIntervalDecimalKey,
} from 'store/reportBuilder'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'
import { COLORS } from 'constants/colors'

/**
 * Munge Functions
 */
const mungeData = (ideasAndThemes, idsSegments, slideSettings) => {
	return ideasAndThemes.map(ideaOrTheme => {
		const isTheme = ideaOrTheme.idStatement === undefined

		const percentAndCompletes =
			slideSettings.themeViewSettings.isActive === true
				? _.flatten(
						idsSegments.map(idSegment => {
							const percent = ideaOrTheme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]

							return [percent]
						}),
				  )
				: _.flatten(
						idsSegments.map(idSegment => {
							const percent = ideaOrTheme[getSegmentTotalSupportDecimalPercentageKey(idSegment)]
							const confidence = ideaOrTheme[getSegmentConfidenceIntervalDecimalKey(idSegment)]
							const agreeCount = ideaOrTheme[getSegmentAgreeCountKey(idSegment)]
							const disagreeCount = ideaOrTheme[getSegmentDisagreeCountKey(idSegment)]
							const indifferentCount = ideaOrTheme[getSegmentIndifferentCountKey(idSegment)]

							return [agreeCount, indifferentCount, disagreeCount, percent, confidence]
						}),
				  )

		const themeName = getStatementThemeName(ideaOrTheme, true)

		const name = isTheme === true ? themeName : ideaOrTheme.label

		const dataToExport =
			slideSettings.themeViewSettings.isActive === true
				? [ideaOrTheme.letter, name, ideaOrTheme.statements?.length || '', ...percentAndCompletes]
				: [ideaOrTheme.letter, name, ...percentAndCompletes, themeName || '']

		const id = ideaOrTheme.idStatement || ideaOrTheme.idStatementTheme

		const customColor = _.get(slideSettings, `customColors.${id}`, null)

		const formattedIdea = {
			..._.pick(
				ideaOrTheme,
				_.flatMap(idsSegments, idSegment =>
					isTheme === true
						? [
								getSegmentTotalSupportPercentageKey(idSegment),
								getSegmentTotalSupportCompletesKey(idSegment),
								getSegmentTotalSupportLowerBoundKey(idSegment),
								getSegmentTotalSupportUpperBoundKey(idSegment),
						  ]
						: [
								getSegmentTotalSupportPercentageKey(idSegment),
								getSegmentTotalSupportCompletesKey(idSegment),
								getSegmentTotalSupportLowerBoundKey(idSegment),
								getSegmentTotalSupportUpperBoundKey(idSegment),
								getSegmentConfidenceIntervalKey(idSegment),
								getSegmentAgreeCountKey(idSegment),
								getSegmentDisagreeCountKey(idSegment),
								getSegmentIndifferentCountKey(idSegment),
						  ],
				),
			),
			answersCount: isTheme === true ? ideaOrTheme.statements.length : null,
			name,
			fill: customColor ?? ideaOrTheme.color,
			hasCustomColor: customColor !== null,
			themeName,
			id,
			letter: ideaOrTheme.letter,
			shown: true,
			unit: '%',
			toExport: () => dataToExport,
			aiDescription: {
				label: name,
			},
		}

		idsSegments.forEach(idSegment => {
			formattedIdea.aiDescription[idSegment] =
				ideaOrTheme[getSegmentTotalSupportPercentageKey(idSegment)]
		})

		return formattedIdea
	})
}

const calculateIdeasAndThemes = (
	idStudy,
	blobData,
	statementsTotalSegmentData,
	themesTotalSegmentData,
	studyObjectData,
	slideSettings,
) => {
	if (slideSettings === undefined) return []

	if (studyObjectData === undefined) {
		return []
	}

	const { idStudyObject, idsSegments, themeViewSettings } = slideSettings

	const statementsToRender = getStatementsToRender(
		statementsTotalSegmentData,
		studyObjectData,
		slideSettings,
	)

	const themesToRender =
		themeViewSettings.isActive === true ? filterOutEmptyThemes(studyObjectData.statementThemes) : []

	const statements = statementsToRender.map(statement => {
		const idea = getLetterAndThemeParams(
			statement,
			statement,
			studyObjectData.statementThemes,
			tools.TOTAL_SEGMENT_UUID,
			statementsTotalSegmentData.statementSupports,
			getShouldUseThemes(slideSettings),
		)

		idea.color = themeViewSettings.isActive === true ? COLORS.REPORT_BUILDER_NO_THEME : idea.color

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, idSegment)
				]

			const segmentStatementData = segmentData.statementSupports.find(
				segmentStatement => segmentStatement.idStatement === statement.idStatement,
			)

			const percentage = Math.round(segmentStatementData.expectedSupport * 100)
			const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
				segmentStatementData.expectedSupport,
			)
			const lowerBound = Math.round(
				segmentStatementData.expectedSupportErrorMargin.lowerBound * 100,
			)
			const upperBound = Math.round(
				segmentStatementData.expectedSupportErrorMargin.upperBound * 100,
			)
			const count = segmentStatementData.absoluteSupport

			const confidenceInterval =
				segmentStatementData.expectedSupport - segmentStatementData.confidenceInterval.lowerBound
			const confidence = Math.round(confidenceInterval * 100)
			const confidenceDecimal = getDecimalPointNumberFromLeadingZeroNumber(confidenceInterval)

			const elaborationCounters = segmentStatementData.elaborationCounters ?? {}

			const agreeCount = elaborationCounters.agreeCount
			const disagreeCount = elaborationCounters.disagreeCount
			const indifferentCount = elaborationCounters.indifferentCount

			idea[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
			idea[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			idea[getSegmentTotalSupportCompletesKey(idSegment)] = Number(count)
			idea[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
			idea[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
			idea[getSegmentConfidenceIntervalDecimalKey(idSegment)] = Number(confidenceDecimal)
			idea[getSegmentConfidenceIntervalKey(idSegment)] = Number(confidence)

			idea[getSegmentAgreeCountKey(idSegment)] = agreeCount
			idea[getSegmentDisagreeCountKey(idSegment)] = disagreeCount
			idea[getSegmentIndifferentCountKey(idSegment)] = indifferentCount
		})

		return idea
	})

	const themes = themesToRender.map(themeDescription => {
		const themeSupport = themesTotalSegmentData.themeSupport[themeDescription.idStatementTheme]

		const theme = {
			...themeDescription,
			...themeSupport,
		}

		idsSegments.forEach(idSegment => {
			const segmentData =
				blobData[
					getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idSegment)
				]

			const segmentStatementData = segmentData.themeSupport[theme.idStatementTheme]

			const lowerBound = Math.round(segmentStatementData.supportStrength.lowerBound * 100)
			const upperBound = Math.round(segmentStatementData.supportStrength.upperBound * 100)
			const percentage = Math.round(segmentStatementData.expectedSupport * 100)
			const percentageDecimal = getDecimalPointNumberFromLeadingZeroNumber(
				segmentStatementData.expectedSupport,
			)
			const count = percentage

			theme[getSegmentTotalSupportDecimalPercentageKey(idSegment)] = Number(percentageDecimal)
			theme[getSegmentTotalSupportPercentageKey(idSegment)] = Number(percentage)
			theme[getSegmentTotalSupportCompletesKey(idSegment)] = Number(count)
			theme[getSegmentTotalSupportLowerBoundKey(idSegment)] = Number(lowerBound)
			theme[getSegmentTotalSupportUpperBoundKey(idSegment)] = Number(upperBound)
		})

		return theme
	})

	return [...themes, ...statements]
}

/**
 * Derivators
 */
export const calculateLegendIdeasAndThemes = (
	idStudy,
	reportType,
	studyObjectData,
	slideSettings,
	blobData,
) => {
	if (reportType !== REPORT_TYPES.OPEN_ANSWERS) {
		return []
	}

	if (studyObjectData === undefined) {
		return []
	}

	if (slideSettings === undefined) return []
	const { idStudyObject, idsSegments, themeViewSettings } = slideSettings

	const statementsDataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT, idSegment)
			],
	)
	const statementsTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_STATEMENT_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	const themesDataArray = idsSegments.map(
		idSegment =>
			blobData[
				getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT, idSegment)
			],
	)
	const themesTotalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_THEME_SUPPORT,
				tools.TOTAL_SEGMENT_UUID,
			)
		]

	if (
		hasBlobData(
			[...statementsDataArray, statementsTotalSegmentData],
			SUPPORT_KEYS.STATEMENT_SUPPORTS,
		) === false
	) {
		return []
	}

	if (
		themeViewSettings.isActive === true &&
		hasBlobData([...themesDataArray, themesTotalSegmentData], SUPPORT_KEYS.THEME_SUPPORT) === false
	) {
		return []
	}

	const ideas = calculateIdeasAndThemes(
		idStudy,
		blobData,
		statementsTotalSegmentData,
		themesTotalSegmentData,
		studyObjectData,
		slideSettings,
	)

	return mungeData(ideas, idsSegments, slideSettings)
}
