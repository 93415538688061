import _ from 'lodash'
import { compare } from 'natural-orderby'

import { filterData, sortData } from 'helpers/sortAndFilter'
import { getBlobDataKey } from 'helpers/reportBuilder/getBlobDataKey'
import { getDecimalPointNumberFromLeadingZeroNumber } from 'helpers/reportBuilder/getDecimalPointNumberFromLeadingZeroNumber'
import { getStatementThemeName } from 'helpers/statementThemes'
import { getThemeParams } from 'helpers/reportBuilder/getLetterAndThemeParams'
import { hasBlobData } from 'helpers/reportBuilder/hasBlobData'

import { tools } from 'constants/tools'
import { REPORT_TYPES, REPORT_BLOB_TYPES, SUPPORT_KEYS } from 'constants/reports'

/**
 * HELPERS
 */
const calculateIdeaData = (idea, chosenSegmentData) => {
	const supportData = chosenSegmentData.statementSupports.find(
		statement => statement.idStatement === idea.idStatement,
	)

	return {
		...idea,
		x: supportData.x,
		y: supportData.y,
		support: { expectedSupport: supportData.expectedSupport },
		support_total: {
			// something is mutating support_total.supportStrength so we need to copy it
			supportStrength: { ...idea.supportStrength },
			expectedSupport: idea.expectedSupport,
			totalSeen: idea.totalSeen,
		},
	}
}

const roundIdea = idea => ({
	...idea,
	x: _.round(idea.x, 2),
	y: _.round(idea.y, 2),
	support_total: {
		...idea.support_total,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(idea, 'support_total.supportStrength.lowerBound', 0),
		),
		expectedSupport: _.round(_.get(idea, 'support_total.expectedSupport', 0), 2) * 100,
		supportStrength: {
			...idea.support_total.supportStrength,
			lowerBound: _.round(_.get(idea, 'support_total.supportStrength.lowerBound', 0), 2) * 100,
		},
	},
	support: {
		...idea.support,
		decimalSupportPercent: getDecimalPointNumberFromLeadingZeroNumber(
			_.get(idea, 'support.expectedSupport', 0),
		),
		expectedSupport: _.round(_.get(idea, 'support.expectedSupport', 0), 2) * 100,
	},
})

const sortIdeaByTotal = (a, b) => {
	const res =
		a.support_total.supportStrength.lowerBound - b.support_total.supportStrength.lowerBound

	if (res < 0) return 1
	if (res > 0) return -1

	return compare(a.idea, b.idea)
}

const formatIdea = (idea, allIdeas, statementThemes, settings) => {
	const { hiddenStatements, useThemes } = settings

	const statementThemeParams = getThemeParams(idea, idea, statementThemes, allIdeas, useThemes)

	const themeName = getStatementThemeName(statementThemeParams, true)

	const customColor = _.get(settings, `customColors.${idea.idStatement}`, null)

	return {
		idStatement: idea.idStatement,
		fill: customColor ?? statementThemeParams.color,
		hasCustomColor: customColor !== null,
		id: idea.idStatement,
		themeName,
		letter: idea.letter,
		name: idea.label,
		unit: '%',
		x: idea.x,
		y: idea.y,

		isExcluded: hiddenStatements.includes(idea.idStatement),

		decimalSupportPercent: idea.support.decimalSupportPercent,
		totalDecimalSupportPercent: idea.support_total.decimalSupportPercent,

		totalExpectedSupport: _.round(idea.support_total.expectedSupport, 2),
		totalSupportCompletes: _.round(idea.support_total.totalSeen, 2),
		totalSupportPercent: _.round(idea.support_total.supportStrength.lowerBound, 2),

		supportPercent: _.round(idea.support.expectedSupport, 2),
	}
}

/**
 * SELECTORS & CALCULATORS
 */

export const calculateDataset = (idStudy, calculatedSlide, blobData) => {
	const {
		settings: { idsSegments, idStudyObject },
		legendState: { sorted, filtered },
		slideType,
		themes: statementThemes,
	} = calculatedSlide

	const totalSegmentData =
		blobData[
			getBlobDataKey(
				idStudy,
				idStudyObject,
				REPORT_BLOB_TYPES.OEQ_IDEACLUSTER,
				tools.TOTAL_SEGMENT_UUID,
			)
		]
	const chosenSegmentData =
		blobData[
			getBlobDataKey(idStudy, idStudyObject, REPORT_BLOB_TYPES.OEQ_IDEACLUSTER, idsSegments[0])
		]

	if (
		slideType !== REPORT_TYPES.IDEACLUSTER ||
		hasBlobData([totalSegmentData, chosenSegmentData], SUPPORT_KEYS.STATEMENT_SUPPORTS) === false
	) {
		return { statementSupports: [], allIdeasLength: 0 }
	}

	const formattedStatements = totalSegmentData.statementSupports
		.map(idea => calculateIdeaData(idea, chosenSegmentData))
		.map(roundIdea)
		.slice()
		.sort(sortIdeaByTotal)
		.map((idea, index, allIdeas) =>
			formatIdea(idea, allIdeas, statementThemes, calculatedSlide.settings),
		)

	const sortedAndFilteredStatements = sortData(filterData(formattedStatements, filtered), sorted)

	return {
		statementSupports: sortedAndFilteredStatements,
		allIdeasLength: formattedStatements.length,
	}
}

export const calculateLegendStatements = (calculatedSlide, dataset) => {
	const { hiddenStatements } = calculatedSlide.settings

	return dataset.statementSupports
		.filter(statement => hiddenStatements.includes(statement.idStatement) === false)
		.map(statement => {
			return {
				...statement,
				toExport: () => [
					statement.letter,
					statement.name,
					statement.totalDecimalSupportPercent,
					statement.totalSupportCompletes,
					statement.totalExpectedSupport,
					statement.decimalSupportPercent,
					statement.themeName,
				],
			}
		})
}
